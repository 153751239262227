import React, { Suspense } from 'react';
//import AppLoader from './AppLoader'
import './scss/bootstrap_pre.scss';
//import 'bootstrap/dist/css/bootstrap.min.css';
import { Spinner, Container } from 'reactstrap';
import loadable from '@react-loadable/revised';
import MainBrand from 'img/brand.svg';
const AppLoadable = loadable({
    loader: () => import('./AppLoader'),
    loading: () => React.createElement(Container, null,
        React.createElement(MainBrand, { className: "w-25" }),
        React.createElement(Spinner, null))
});
export default function App() {
    return React.createElement("div", { id: "app" },
        React.createElement(Suspense, { fallback: React.createElement(Spinner, null) },
            React.createElement(AppLoadable, null)));
}
