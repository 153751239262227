import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Spinner } from 'reactstrap';
import './scss/index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
ReactDOM.render(React.createElement(React.Fragment, null,
    React.createElement(Suspense, { fallback: React.createElement(Spinner, null) },
        React.createElement(App, null))), document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
